import { InfoOutlined } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Sandbox, SuccessEvent, useFinchConnect } from '@tryfinch/react-connect';
import {
    CREATE_FINCH_ACCESS_TOKEN_ACTION,
    createFinchAccessToken,
} from 'actions/finch/createFinchAccessToken';
import { FinchIntegrationStatuses, HrsIntegrationProviders, Products } from 'api/generated/enums';
import Button from 'components/Button';
import ProfileAttribute from 'components/ProfileAttribute';
import Toast from 'components/Toast';
import Tooltip from 'components/Tooltip';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import React, { useCallback } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { FINCH_CLIENT_ID, FINCH_CONNECT_SANDBOX } from 'utilities/finch';
import { hasValue } from 'utilities/index';

function toSnakeCase(str: string): string {
    return str
        .replace(/([A-Z])/g, '_$1')
        .toLowerCase()
        .replace(/^_/, '');
}

function getEnumStringValues<T extends Record<string, number | string>>(enumObj: T): string[] {
    return Object.keys(enumObj)
        .filter((key) => isNaN(Number(key)))
        .map((key) => toSnakeCase(key));
}

const requiredProducts = getEnumStringValues(Products);

const FinchIntegrationCard = () => {
    const dispatch = useThunkDispatch();
    const { team } = useTeamProps();
    const { isSaving } = useSelector((state: AppStore) => ({
        isSaving: hasApiActivity(state, CREATE_FINCH_ACCESS_TOKEN_ACTION),
    }));

    const { open } = useFinchConnect({
        clientId: FINCH_CLIENT_ID,
        onError: () => Toast.error('Failed to connect with Finch'),
        onSuccess: (value: SuccessEvent) => {
            if (team) {
                const code = value.code;
                const teamId = team.teamId;
                dispatch(createFinchAccessToken(code, teamId));
            }
        },
        payrollProvider: team?.finchProviderId,
        products: requiredProducts,
        sandbox: hasValue(FINCH_CONNECT_SANDBOX) ? (FINCH_CONNECT_SANDBOX as Sandbox) : undefined,
        zIndex: 2000,
    });

    const OverrideActionButtons = useCallback(
        () => (
            <Typography fontWeight={700} variant="h4">
                <Tooltip title="Our HRIS integrations allow you to connect your HR information system for seamless exchange of employee and payroll data. Remodel Health uses Finch, an aggregator service, to securely integrate with various employment and payroll providers. Support for payroll data integration varies by HRIS provider.">
                    <InfoOutlined color="secondary" />
                </Tooltip>
            </Typography>
        ),
        []
    );

    return team?.hrsIntegrationProvider !== HrsIntegrationProviders.Finch ? (
        <React.Fragment />
    ) : (
        <Card>
            <CardContent>
                <DashboardCardHeader
                    header="HRIS Integration"
                    OverrideActionButtons={OverrideActionButtons}
                />
                <hr></hr>
                {team?.finchIntegrationStatus !== FinchIntegrationStatuses.NotConfigured ? (
                    <React.Fragment>
                        <ProfileAttribute label="Finch Connection Status">
                            {startCase(
                                FinchIntegrationStatuses[
                                    team?.finchIntegrationStatus ??
                                        FinchIntegrationStatuses.NotConfigured
                                ]
                            )}
                        </ProfileAttribute>
                        {team?.finchProviderName && (
                            <ProfileAttribute label="Finch Provider">
                                {startCase(team?.finchProviderName)}
                            </ProfileAttribute>
                        )}
                    </React.Fragment>
                ) : (
                    <Stack alignItems="center">
                        <Button
                            className="m-2"
                            isLoading={isSaving}
                            onClick={() => open()}
                            size="small"
                        >
                            Connect to HRIS
                        </Button>
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
};

export default hot(module)(FinchIntegrationCard);
