import { getFinchProviders } from 'actions/finch/getFinchProviders';
import { FinchIntegrationStatuses } from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { IValueType } from 'components/EditableAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { onChange } from 'utilities/forms';
import { string } from 'yup';

const FinchIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const dispatch = useThunkDispatch();
    const { team } = useTeamProps();
    const { finchProviders } = useSelector((state: AppStore) => ({
        finchProviders: state.finch.providers,
    }));

    const [finchProviderId, setFinchProviderId] = useState<string | undefined>(
        team?.finchProviderId
    );

    const saveFinchProviderId = useCallback(
        async (name, value) => {
            await save(name, value);
        },
        [save]
    );

    useEffect(() => {
        if (finchProviders === null || (finchProviders && finchProviders.length === 0)) {
            dispatch(getFinchProviders());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <React.Fragment>
            <ProfileAttribute label="Status">
                {startCase(
                    FinchIntegrationStatuses[
                        team?.finchIntegrationStatus ?? FinchIntegrationStatuses.NotConfigured
                    ]
                )}
            </ProfileAttribute>
            {finchProviders && (
                <EditableSelectAttribute
                    formatter={(x: IValueType) =>
                        finchProviders.find((p) => p.id === x)?.displayName ?? ''
                    }
                    hrClassName="d-none"
                    items={finchProviders}
                    label="Provider"
                    name="finchProviderId"
                    onChange={onChange(setFinchProviderId)}
                    optionText="displayName"
                    optionValue="id"
                    save={saveFinchProviderId}
                    validationSchema={string().label('Provider')}
                    value={finchProviderId}
                />
            )}
        </React.Fragment>
    );
};

export default hot(module)(FinchIntegrationSection);
